import APIDataProvider from '../api/APIDataProvider';
import API from '../api';

export default function ({ days, children }) {
	console.log('days', days);
	return (
		<APIDataProvider method={API.massDMQueue.bind(API)}>
			{(massDM) => (
				<APIDataProvider
					method={
						days === 'wall' ? API.paidPosts.bind(API) : API.topContent.bind(API)
					}
					options={{ days }}
				>
					{(topContent, loadingTopContent) =>
						children(massDM, topContent, loadingTopContent)
					}
				</APIDataProvider>
			)}
		</APIDataProvider>
	);
}
