import { Card, Title } from '@tremor/react';
import React from 'react';
import SKUEditor from '../components/SKUEditor';

export default function ({ data, title, setSelected }) {
	if (!data) return '';
	console.log(data && data.length);
	return (
		<div>
			<Title>{title}</Title>
			<div
				style={{
					overflow: 'scroll',
					height: 'calc(100vh - 300px)',
				}}
			>
				{data.map((x) => (
					<Card
						onClick={() => setSelected(x)}
						key={x.hash + x.template.queueId}
						className="mt-6"
					>
						<Title>
							<SKUEditor sku={x.hash} override={x.overrideSKU} />
						</Title>
					</Card>
				))}
			</div>
		</div>
	);
}
