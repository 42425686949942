import React from 'react';
import { Card, Text, Flex, Badge, Button, Title } from '@tremor/react';
import { formatRelative } from 'date-fns';
import SKUEditor from '../components/SKUEditor';
import ImageScroller from './ImageScroller';

export default function ({ content, removePending, showDetail }) {
	return (
		<div>
			<Title>Queue</Title>

			<div
				style={{
					overflow: 'scroll',
					height: 'calc(100vh - 300px)',
				}}
			>
				{content.map((x) => (
					<Card
						decoration={x.type === 'pending' ? 'top' : ''}
						decorationColor="yellow"
						className="mt-6"
						key={x.id}
					>
						{x.type === 'pending' ? (
							<div className="flex justify-end">
								<Button onClick={() => removePending(x.id)} color="rose">
									Remove
								</Button>
							</div>
						) : (
							''
						)}
						<Flex>
							<Title>
								<SKUEditor sku={x.hash} override={x.overrideSKU} />
							</Title>
							<div className="whitespace-nowrap">
								<Badge color="indigo">${x.price}</Badge>
								<Badge>
									{formatRelative(
										new Date(x.scheduledAt || x.createdAt),
										new Date()
									)}
								</Badge>
							</div>
						</Flex>
						{showDetail === 'yes' ? (
							<>
								<div
									className="mt-4 mb-4"
									style={{
										overflowX: 'scroll',
										whiteSpace: 'nowrap',
									}}
								>
									<ImageScroller
										ids={x.media}
										previews={x.previews || x.preview}
									/>
								</div>
								<Text>{x.text}</Text>
							</>
						) : null}
					</Card>
				))}
			</div>
		</div>
	);
}
