import {
	Card,
	Grid,
	Text,
	Metric,
	DeltaBar,
	Flex,
	Title,
	BarChart,
	LineChart,
	TabGroup,
	TabList,
	Tab,
} from '@tremor/react';

import React, { useState } from 'react';

import { startOfMonth } from 'date-fns';
import API from './api';
import APIDataProvider from './api/APIDataProvider';

import {
	formatBigMoney,
	formatBigMoneyChange,
	percentChange,
} from './utils/format';

export default function () {
	const from = startOfMonth(new Date());
	const to = new Date();
	const [includeExcluded, setIncludeExcluded] = useState(0);
	return (
		<>
			<Flex justifyContent="end">
				<TabGroup
					onIndexChange={setIncludeExcluded}
					index={includeExcluded}
					className="w-auto"
				>
					<TabList variant="solid" className="mt-6">
						<Tab>Exclude</Tab>
						<Tab>Include Excluded</Tab>
					</TabList>
				</TabGroup>
			</Flex>
			<Grid numItemsMd={3} numItemsLg={4} className="gap-6 mt-6">
				<APIDataProvider
					method={API.stats.bind(API)}
					options={{ ...(includeExcluded ? { includeExcluded: true } : {}) }}
				>
					{(stats, loading) => (
						<>
							<Card>
								<Text>Month so far</Text>
								<Metric>
									{stats ? formatBigMoney(stats.month) : 'Loading'}
								</Metric>
								<Flex className="mt-6">
									<Text>vs.&nbsp;Last&nbsp;Month</Text>
									<Flex
										alignItems="baseline"
										justifyContent="end"
										className="space-x-1"
									>
										<Text>
											{stats
												? formatBigMoneyChange(
														stats.month - stats.lastMonthToNow
												  )
												: 'Loading'}
										</Text>
										<Text>
											(
											{stats
												? percentChange(stats.month / stats.lastMonthToNow - 1)
												: 'Loading'}
											)
										</Text>
									</Flex>
								</Flex>
								<DeltaBar
									value={
										stats ? (stats.month / stats.lastMonthToNow - 1) * 100 : 0
									}
									isIncreasePositive
									className="mt-3"
								/>
							</Card>
							<Card>
								<Text>Predicted</Text>
								<Metric>
									{stats ? formatBigMoney(stats.predicted) : 'Loading'}
								</Metric>
								<Flex className="mt-6">
									<Text>vs.&nbsp;Last&nbsp;Month</Text>
									<Flex
										alignItems="baseline"
										justifyContent="end"
										className="space-x-1"
									>
										<Text>
											{stats
												? formatBigMoneyChange(
														stats.predicted - stats.lastMonth
												  )
												: 'Loading'}
										</Text>
										<Text>
											(
											{stats
												? percentChange(stats.predicted / stats.lastMonth - 1)
												: 'Loading'}
											)
										</Text>
									</Flex>
								</Flex>
								<DeltaBar
									value={
										stats ? (stats.predicted / stats.lastMonth - 1) * 100 : 0
									}
									isIncreasePositive
									className="mt-3"
								/>
							</Card>
							<Card>
								<Text>Last month to current date</Text>
								<Metric>
									{stats ? formatBigMoney(stats.lastMonthToNow) : 'Loading'}
								</Metric>
							</Card>
							<Card>
								<Text>Last month</Text>
								<Metric>
									{stats ? formatBigMoney(stats.lastMonth) : 'Loading'}
								</Metric>
							</Card>
						</>
					)}
				</APIDataProvider>
			</Grid>
			<Grid numItemsMd={1} numItemsLg={2} className="gap-6 mt-6">
				<APIDataProvider
					method={API.sales.bind(API)}
					options={{
						from,
						to,
						timeGroup: 'day',
						...(includeExcluded ? { includeExcluded: true } : {}),
					}}
				>
					{(sales, loading) => (
						<>
							<Card>
								<Title>Daily sales</Title>
								<BarChart
									className="mt-6 h-56 sm:h-72"
									data={sales || []}
									index="date"
									categories={['net']}
									colors={['emerald']}
									valueFormatter={formatBigMoney}
									yAxisWidth={40}
								/>
							</Card>
							<Card>
								<Title>Cumulative sales</Title>
								<LineChart
									className="mt-6 h-56 sm:h-72"
									data={(sales || []).reduce((o, x, i, a) => {
										o.push({
											...x,
											Cumulative: (o[i - 1] ? o[i - 1].Cumulative : 0) + x.net,
											Target: i * 2350,
										});
										return o;
									}, [])}
									index="date"
									categories={['Cumulative', 'Target']}
									colors={['cyan', 'gray']}
									valueFormatter={formatBigMoney}
									yAxisWidth={40}
								/>
							</Card>
						</>
					)}
				</APIDataProvider>
			</Grid>
		</>
	);
}
