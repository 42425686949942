import { Card, Title } from '@tremor/react';
import React, { useState } from 'react';

export default function () {
	return (
		<Card className="mt-6">
			<Title>Day Review</Title>
			<XYPlot xType="ordinal" width={300} height={300} xDistance={100}>
				<VerticalGridLines />
				<HorizontalGridLines />
				<XAxis />
				<YAxis />
				<BarSeries className="vertical-bar-series-example" data={greenData} />
				<BarSeries data={blueData} />
				<LabelSeries data={labelData} getLabel={(d) => d.x} />
			</XYPlot>
		</Card>
	);
}
