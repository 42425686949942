import { Card, Metric, TextInput } from '@tremor/react';

import React, { useState } from 'react';
import ImageScroller from '../scheduler/ImageScroller';
import api from '../api';

export default function ({ selectedToTag, addTag }) {
	const [tag, setTag] = useState();

	if (!selectedToTag) return '';
	return (
		<Card className="mt-6" decoration="top" decorationColor="emerald">
			<Metric>{selectedToTag.overrideSKU || selectedToTag.hash}</Metric>
			<div
				className="mt-4 mb-4"
				style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}
			>
				<ImageScroller
					ids={selectedToTag.template.media}
					previews={
						selectedToTag.template.previews || selectedToTag.template.preview
					}
				></ImageScroller>
			</div>
			<div>
				<TextInput
					value={tag}
					autoFocus
					onChange={({ currentTarget }) => setTag(currentTarget.value)}
					onKeyDown={async (x) => {
						if (x.key === 'Enter') {
							addTag(selectedToTag.hash, tag);
							setTag('');
						}
					}}
				></TextInput>
			</div>
		</Card>
	);
}
