import queryString from 'query-string';

class API {
	constructor() {
		console.log('Process.env ', process.env);
		this.baseURL =
			process.env.REACT_APP_API_URL || 'https://of-manager.d3v.run';
		this.username = localStorage.getItem('of-username');
		this.password = localStorage.getItem('of-password');
	}

	setCreds(username, password) {
		this.username = username;
		this.password = password;
	}

	async request(method, url, options) {
		const uri =
			this.baseURL +
			url +
			(method === 'GET' ? `?${queryString.stringify(options)}` : '');

		const req = await fetch(uri, {
			headers: {
				...(method === 'POST' ? { 'Content-Type': 'application/json' } : {}),
				Authorization: `Basic ${btoa(`${this.username}:${this.password}`)}`,
			},

			method,

			...(method === 'POST'
				? {
						body: JSON.stringify(options),
				  }
				: {}),
		});
		if (req.status >= 400) throw new Error(`Code: ${req.status}`);
		const res = await req.json();

		return res;
	}

	/*
    {
		timeGroup: //day, month, week, year, hour, minute
		from: //date
		to: //date
		groupType: // true / false (Messages/Tips/Subs/Excluded)
		filterType: // (Messages/Tips/Subs/Excluded
		includeExcluded: // true / false
	}
    */
	sales(options) {
		return this.request('GET', '/sales', options);
	}

	metricsQuery(options) {
		return this.request('GET', '/metrics-query', options);
	}

	todayVsAverage(options) {
		return this.request('GET', '/today-vs-average-day', options);
	}

	metrics(date) {
		return this.request('GET', '/metrics', { date });
	}

	stats(options) {
		return this.request('GET', '/stats', options);
	}

	timeTracking() {
		return this.request('GET', '/time-tracking');
	}

	topContent(options) {
		return this.request('GET', '/top-content', options);
	}

	paidPosts(options) {
		return this.request('GET', '/paid-posts', options);
	}

	massDMQueue() {
		return this.request('GET', '/mass-dm-queue');
	}

	updateSKUName(sku, name) {
		return this.request('POST', `/sku-name/${sku}`, { name });
	}

	scheduleQueueMessage(message) {
		return this.request('POST', '/schedule-queue-message', message);
	}

	textForQueueItem(hash) {
		return this.request('GET', '/text-for-queue-item', { hash });
	}

	untaggedContent() {
		return this.request('GET', '/untagged-content');
	}

	taggedContent() {
		return this.request('GET', '/tagged-content');
	}

	addTag(sku, tag) {
		return this.request('POST', `/tag-sku/${sku}`, { tag });
	}
}

export default new API();
