import React, { useState } from 'react';
import api from '.';

export const UserContext = React.createContext(null);

export default function ({ children }) {
	const [loggedIn, setLoggedIn] = useState(false);
	const [displayUsername, setUsername] = useState(
		localStorage.getItem('username')
	);
	if (localStorage.getItem('username')) {
		api.setCreds(
			localStorage.getItem('username'),
			localStorage.getItem('password')
		);
		if (localStorage.getItem('username') !== displayUsername)
			setUsername(localStorage.getItem('username'));
	}

	const login = (username, password) => {
		console.log('Login');
		if (username) {
			api.setCreds(username, password);
			if (username !== displayUsername) setUsername(username);
			localStorage.setItem('username', username);
			localStorage.setItem('password', password);
		}
		api
			.metrics()
			.then(() => {
				setLoggedIn(true);
			})
			.catch((e) => {
				if (e.message === 'Code: 401') {
					console.log('Not logged in');
				}
			});
	};

	const logout = () => {
		localStorage.removeItem('username');
		localStorage.removeItem('password');
		api.setCreds(null, null);
		setLoggedIn(false);
	};

	if (!loggedIn) {
		login();
	}
	return (
		<UserContext.Provider value={{ user: displayUsername, logout }}>
			{children(loggedIn, login, displayUsername, logout)}
		</UserContext.Provider>
	);
}
