import React, { useState } from 'react';
import api from '../api';

export default function ({ sku, override }) {
	const [content, setContent] = useState(override || sku);

	const updateSKU = ({ currentTarget }) => {
		const text = currentTarget.innerText;
		api.updateSKUName(sku, text);
	};

	return (
		<div suppressContentEditableWarning contentEditable onBlur={updateSKU}>
			{content}
		</div>
	);
}
