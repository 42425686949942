import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function ({ ids, previews }) {
	return (
		<div
			className="mt-4 mb-4"
			style={{
				overflowX: 'scroll',
				whiteSpace: 'nowrap',
			}}
		>
			{ids.map((x, i) => (
				<LazyLoadImage
					height={80}
					className="ml-2"
					key={`${x.id || x}:${i}`}
					style={{
						display: 'inline-block',
						height: 80,
						border: previews.includes(x.id || x) ? '2px solid #10b981' : '',
					}}
					src={`https://of-manager.d3v.run/media-item/${x.id || x}/preview`}
				/>
			))}
		</div>
	);
}
