import { Card, Text, Flex, Metric, Badge, Title } from '@tremor/react';
import React from 'react';

import { formatRelative } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import SKUEditor from '../components/SKUEditor';
import { niceNumber } from '../utils/format';
import ImageScroller from './ImageScroller';

console.log(enGB);

export default function ({ content, setSelected, showDetail }) {
	return (
		<div>
			<Title>Content</Title>
			<div
				style={{
					overflow: 'scroll',
					height: 'calc(100vh - 300px)',
				}}
			>
				{content.map((x) => (
					<Card
						key={x.sku + x.template.queueId}
						className="mt-6"
						onClick={() =>
							setSelected({
								...x,
							})
						}
					>
						<Title>
							<SKUEditor sku={x.hash} override={x.overrideSKU} />
						</Title>
						{showDetail === 'yes' ? (
							<>
								<ImageScroller
									ids={x.template.media}
									previews={x.template.previews || x.template.preview}
								></ImageScroller>
								<Text>{x.text || x.template.text}</Text>
							</>
						) : null}

						<Flex>
							<Flex className="mt-4 space-x-2 mx-2">
								<Text className="truncate">Sold</Text>
								<Badge>{x.bought}</Badge>
							</Flex>
							<Flex className="mt-4 space-x-2 mx-2">
								<Text className="truncate">Sent</Text>
								<Badge>{x.sent}</Badge>
							</Flex>
						</Flex>
						<Flex>
							<Flex className="mt-4 space-x-2 mx-2">
								<Text className="truncate">Sold per</Text>
								<Badge>{niceNumber(x.soldPer, 0)}</Badge>
							</Flex>
							<Flex className="mt-4 space-x-2 mx-2">
								<Text className="truncate">Last scheduled</Text>
								<Badge>
									{x.lastScheduled || x.lastSent
										? formatRelative(
												new Date(x.lastScheduled || x.lastSent),
												new Date(),
												{ locale: enGB }
										  )
										: ''}
								</Badge>
							</Flex>
						</Flex>
					</Card>
				))}
			</div>
		</div>
	);
}
