import { Card, Text, Flex, Metric, TextInput, Button } from '@tremor/react';

import React, { useState } from 'react';
import ImageScroller from './ImageScroller';
import APIDataProvider from '../api/APIDataProvider';
import api from '../api';
import { niceNumber } from '../utils/format';

export default function ({
	selectedToAdd,
	addSelectedToStagingList,
	setSelected,
}) {
	const [selectedTextIndex, setSelectedTextIndex] = useState(0);

	const incr = (amt, arr) => () => {
		let n = selectedTextIndex + amt;
		if (n >= arr.length) n = arr.length - 1;
		if (n < 0) n = 0;
		setSelectedTextIndex(n);

		setSelected({
			...selectedToAdd,
			template: {
				...selectedToAdd.template,
				text: arr[n].text,
			},
		});
	};
	return (
		<APIDataProvider
			method={api.textForQueueItem.bind(api)}
			options={selectedToAdd.hash}
			loaded={(result) => {
				setSelectedTextIndex(0);
				setSelected({
					...selectedToAdd,
					template: {
						...selectedToAdd.template,
						text: result[0]?.text || selectedToAdd.template.text,
					},
				});
			}}
		>
			{(textData) =>
				textData && (
					<Card className="mt-6" decoration="top" decorationColor="emerald">
						<Metric>{selectedToAdd.overrideSKU || selectedToAdd.hash}</Metric>
						<div
							className="mt-4 mb-4"
							style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}
						>
							<ImageScroller
								ids={selectedToAdd.template.media}
								previews={
									selectedToAdd.template.previews ||
									selectedToAdd.template.preview
								}
							></ImageScroller>
						</div>
						<Flex className="gap-2 mt-6">
							<Text>Price</Text>
							<TextInput
								onChange={({ currentTarget }) =>
									setSelected({
										...selectedToAdd,
										template: {
											...selectedToAdd.template,
											price: Number(currentTarget.value),
										},
									})
								}
								value={selectedToAdd.template.price}
							></TextInput>
						</Flex>
						<Text>
							<Button variant="light" onClick={incr(-1, textData)}>
								Prev&nbsp;
							</Button>
							{selectedTextIndex + 1} / {textData.length}
							<Button variant="light" onClick={incr(1, textData)}>
								&nbsp;Next
							</Button>
						</Text>
						<Text>
							{niceNumber(textData[selectedTextIndex]?.soldPer, 1)} sold per
						</Text>
						<textarea
							value={selectedToAdd.template.text.replaceAll('<br />', '')}
							onChange={({ currentTarget }) =>
								setSelected({
									...selectedToAdd,
									template: {
										...selectedToAdd.template,
										text: currentTarget.value,
									},
								})
							}
							className="mt-2 relative w-full flex items-center min-w-[10rem] outline-none rounded-tremor-default shadow-tremor-input dark:shadow-dark-tremor-input bg-tremor-background dark:bg-dark-tremor-background hover:bg-tremor-background-muted dark:hover:bg-dark-tremor-background-muted text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis border-tremor-border dark:border-dark-tremor-border border w-full focus:outline-none focus:ring-0 bg-transparent text-tremor-default text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis pl-4 pr-4 py-2 placeholder:text-tremor-content dark:placeholder:text-dark-tremor-content h-56"
						></textarea>
						<input
							type="datetime-local"
							className="mt-2 relative w-full flex items-center min-w-[10rem] outline-none rounded-tremor-default shadow-tremor-input dark:shadow-dark-tremor-input bg-tremor-background dark:bg-dark-tremor-background hover:bg-tremor-background-muted dark:hover:bg-dark-tremor-background-muted text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis border-tremor-border dark:border-dark-tremor-border border w-full focus:outline-none focus:ring-0 bg-transparent text-tremor-default text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis pl-4 pr-4 py-2 placeholder:text-tremor-content dark:placeholder:text-dark-tremor-content"
							value={selectedToAdd.scheduleTime}
							onClick={({ currentTarget }) => currentTarget.showPicker()}
							onChange={({ currentTarget }) =>
								setSelected({
									...selectedToAdd,
									scheduleTime: currentTarget.value,
								})
							}
						></input>
						<Button
							variant="primary"
							className="mt-6"
							onClick={addSelectedToStagingList}
						>
							Add
						</Button>
					</Card>
				)
			}
		</APIDataProvider>
	);
}
