import React from 'react';
import { SelectItem, Select, Flex } from '@tremor/react';

export default function ({
	value,
	onValueChange,
	showDetail,
	showDetailChange,
	sort,
	sortChange,
	sortOrder,
	sortOrderChange,
}) {
	return (
		<div className="max-w-sm mt-6 space-y-6">
			<Flex>
				<Select value={value} className="mr-2" onValueChange={onValueChange}>
					<SelectItem value={1}>1 Day</SelectItem>
					<SelectItem value={7}>7 Days </SelectItem>
					<SelectItem value={14}>14 Days</SelectItem>
					<SelectItem value={30}>30 Days</SelectItem>
					<SelectItem value={60}>60 Days</SelectItem>
					<SelectItem value="wall">Wall content</SelectItem>
				</Select>
				<Select
					value={showDetail}
					className="mr-2"
					onValueChange={showDetailChange}
				>
					<SelectItem value="yes">Show detail</SelectItem>
					<SelectItem value="no">Hide detail</SelectItem>
				</Select>
				<Select
					value={sort}
					onValueChange={sortChange}
					className="mr-2"
					placeholder="Sort by..."
				>
					<SelectItem value="bought">Sold</SelectItem>
					<SelectItem value="sent">Sent</SelectItem>
					<SelectItem value="soldPer">Sold per</SelectItem>
					<SelectItem value="lastSent">Last scheduled</SelectItem>
				</Select>
				<Select
					value={sortOrder}
					onValueChange={sortOrderChange}
					placeholder="Order..."
				>
					<SelectItem value={1}>Asc</SelectItem>
					<SelectItem value={-1}>Desc</SelectItem>
				</Select>
			</Flex>
		</div>
	);
}
