import { Card, Title, TextInput, Button, Flex } from '@tremor/react';
import { useState } from 'react';

export default function ({ login }) {
	const [username, setUsername] = useState();
	const [password, setPassword] = useState();

	return (
		<Card className="w-72 mx-auto">
			<Title>Login</Title>
			<TextInput
				className="mt-6"
				placeholder="Username"
				value={username}
				onChange={({ currentTarget }) => setUsername(currentTarget.value)}
			/>
			<TextInput
				className="mt-2"
				placeholder="Password"
				type="password"
				value={password}
				onChange={({ currentTarget }) => setPassword(currentTarget.value)}
			/>
			<Flex className="justify-end">
				<Button className="mt-4" onClick={() => login(username, password)}>
					Login
				</Button>
			</Flex>
		</Card>
	);
}
