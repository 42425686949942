import React from 'react';

import SessionManager from './api/SessionManager';
import AppScreen from './AppScreen';
import LoginScreen from './LoginScreen';

export default function DashboardExample() {
	return (
		<main className="px-4 py-4  sm:px-12 sm:py-12 bg-tremor-background dark:bg-slate-950 ">
			<SessionManager>
				{(loggedIn, login, username, logout) =>
					!loggedIn ? <LoginScreen login={login} /> : <AppScreen></AppScreen>
				}
			</SessionManager>
		</main>
	);
}
