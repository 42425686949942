import {
	BarChart,
	Card,
	DateRangePicker,
	Flex,
	LineChart,
	Tab,
	TabGroup,
	TabList,
	Title,
} from '@tremor/react';

import React, { useState } from 'react';
import { startOfMonth } from 'date-fns';
import APIDataProvider from './api/APIDataProvider';
import api from './api';

const fixForTypeGrouping = (sales) =>
	sales.reduce((o, x) => {
		let f = o.find((y) => y.date === x.date);
		if (!f) {
			f = { date: x.date };
			o.push(f);
		}
		f[x.type] = x.net;
		return o;
	}, []);

export default function () {
	const [value, setValue] = useState({
		from: startOfMonth(new Date()),
		to: new Date(),
	});

	const [dateGroup, setDateGroup] = useState(0);
	const [typeGroup, setTypeGroup] = useState(0);
	const [chartType, setChartType] = useState(0);

	const typeGroupCategories = ['Subscription', 'Message', 'Tip', 'Post'];

	const dateGroupNames = ['month', 'week', 'day', 'hour', 'minute'];

	return (
		<>
			<Flex className="mt-6" justifyContent="end">
				<DateRangePicker value={value} onValueChange={setValue} />
			</Flex>
			<APIDataProvider
				method={api.sales.bind(api)}
				options={{
					from: value.from,
					to: value.to,
					timeGroup: dateGroupNames[dateGroup],
					groupType: typeGroup ? true : undefined,
				}}
			>
				{(sales) => (
					<Card className="mt-6">
						<Flex justifyContent="between">
							<Title>Sales</Title>
							<div className="flex">
								<TabGroup
									className="mx-2"
									onIndexChange={setTypeGroup}
									index={typeGroup}
								>
									<TabList className="mt-8" variant="solid">
										<Tab>Total</Tab>
										<Tab>Group</Tab>
									</TabList>
								</TabGroup>
								<TabGroup onIndexChange={setChartType} index={chartType}>
									<TabList className="mt-8" variant="solid">
										<Tab>Line</Tab>
										<Tab>Bar</Tab>
									</TabList>
								</TabGroup>
							</div>
						</Flex>
						<TabGroup onIndexChange={setDateGroup} index={dateGroup}>
							<TabList
								className="mt-8"
								index={dateGroup}
								setValue={setDateGroup}
							>
								<Tab>Month</Tab>
								<Tab>Week</Tab>
								<Tab>Day</Tab>
								<Tab>Hour</Tab>
								<Tab>Minute</Tab>
							</TabList>
						</TabGroup>
						{!chartType ? (
							<LineChart
								data={typeGroup ? fixForTypeGrouping(sales) : sales}
								categories={typeGroup ? typeGroupCategories : ['net']}
								index="date"
							/>
						) : (
							<BarChart
								data={typeGroup ? fixForTypeGrouping(sales) : sales}
								categories={typeGroup ? typeGroupCategories : ['net']}
								index="date"
							/>
						)}
					</Card>
				)}
			</APIDataProvider>
		</>
	);
}
