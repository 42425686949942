export const formatBigMoney = (n) => `$${niceNumber(n, 0)}`;
export const formatBigMoneyChange = (n) =>
	(n < 0 ? '-' : '+') + formatBigMoney(Math.abs(n));

export const percent = (n) => `${niceNumber(n * 100, 1)}%`;
export const percentChange = (n) =>
	`${(n < 0 ? '-' : '+') + niceNumber(Math.abs(n * 100), 1)}%`;

export const niceNumber = (n = 0, decimals = 0) =>
	(n || 0).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const formatChange = (n, d) =>
	`${n < 0 ? '-' : '+'}${niceNumber(Math.abs(n), d)}`;
