import { Component } from 'react';

class APIDataProvider extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.state = { loadingOptions: ' ' };
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate({ options: prevOptions }) {
		const { options } = this.props;
		if (JSON.stringify(prevOptions) !== JSON.stringify(options)) this.load();
	}

	load() {
		const { method, options, loaded } = this.props;
		const { loadingOptions } = this.state;
		const staticOptions = JSON.stringify(options);
		const staticMethod = method;
		if (loadingOptions === staticOptions) return;
		this.setState({
			loading: true,
			loadingOptions: JSON.stringify(options),
		});

		this.props
			.method(options)
			.then((result) => {
				if (this.state.loadingOptions === staticOptions) {
					if (loaded) loaded(result);
					this.setState({ result, loading: false });
				}
			})
			.catch((e) => console.log(e));
	}

	render() {
		const { children } = this.props;
		return children(this.state.result, this.state.loading);
	}
}

export default APIDataProvider;
