import { Grid } from '@tremor/react';
import React, { useState } from 'react';
import api from '../api';
import ContentItemList from './ContentItemList';
import Tagger from './Tagger';

export default function () {
	const [untagged, setUntagged] = useState([]);
	const [tagged, setTagged] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState();

	const addTag = async (hash, tag) => {
		const inTagged = tagged.find((x) => x.hash === hash);
		const inUntagged = untagged.find((x) => x.hash === hash);
		const obj = inTagged || inUntagged;

		console.log(inTagged, inUntagged);
		// await api.addTag(hash, tag);

		obj.tags = obj.tags || [];
		obj.tags.push(tag);

		if (!inTagged) {
			const newTagged = [obj, ...tagged];
			setTagged(newTagged);
		}

		if (inUntagged) {
			const newUntagged = untagged.filter((x) => x.hash !== hash);
			setUntagged(newUntagged);
		}
	};

	if (!loading) {
		setLoading(true);
		api.untaggedContent().then((d) => {
			setUntagged(d);
		});
		api.taggedContent().then((d) => {
			setTagged(d);
		});
	}

	return (
		<Grid numItemsMd={1} numItemsLg={3} className="gap-6 mt-6">
			<ContentItemList
				title="Untagged"
				data={untagged}
				setSelected={setSelected}
			/>
			<ContentItemList title="Tagged" data={tagged} setSelected={setSelected} />
			<Tagger selectedToTag={selected} addTag={addTag} />
		</Grid>
	);
}
