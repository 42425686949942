import React, { useContext, useState } from 'react';
import {
	Title,
	Tab,
	TabList,
	TabGroup,
	TabPanel,
	TabPanels,
	Text,
	Button,
	Flex,
} from '@tremor/react';

import {
	createBrowserRouter,
	RouterProvider,
	Link,
	useLocation,
} from 'react-router-dom';
import Today from './Today';
import Month from './Month';
import Leaderboard from './Leaderboard';
import Scheduler from './scheduler/Scheduler';
import Sales from './Sales';
import Metrics from './Metrics';
import { UserContext } from './api/SessionManager';
import DayReview from './DayReview';
import Content from './content/Content';

function Root({ children }) {
	const location = useLocation();
	const pathMap = {
		'/': 0,
		'/month': 1,
		'/leaderboard': 2,
		'/sales': 3,
		'/metrics': 4,
		'/scheduler': 5,
	};
	const [activeIndex, setActiveIndex] = useState(pathMap[location.pathname]);
	const { user, logout } = useContext(UserContext);

	return (
		<>
			<div className="md:flex justify-between items-center">
				<div>
					{/* <Title>Sales Data</Title> */}
					{/* <Text>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</Text> */}
				</div>
				<div>
					<Flex>
						<Text className="cursor-pointer mr-2">Logged in as {user}</Text>
						<Button variant="light" onClick={logout}>
							Logout
						</Button>
					</Flex>
				</div>
				{/* <DateRangePicker onValueChange={setValue} value={value}/> */}
			</div>
			<TabGroup
				className="mt-6"
				onIndexChange={setActiveIndex}
				index={activeIndex}
			>
				<TabList>
					<Link to="/">
						<Tab>Today</Tab>
					</Link>
					<Link to="/month">
						<Tab>Month</Tab>
					</Link>
					<Link to="/leaderboard">
						<Tab>Leaderboard</Tab>
					</Link>
					<Link to="/sales">
						<Tab>Sales</Tab>
					</Link>
					<Link to="/metrics">
						<Tab>Metrics</Tab>
					</Link>
					<Link to="/scheduler">
						<Tab>Scheduler</Tab>
					</Link>
					{/* <Link to="/day-review">
						<Tab>Day Review</Tab>
					</Link> */}
					<Link to="/content">
						<Tab>Content Tagging</Tab>
					</Link>
				</TabList>
			</TabGroup>
			{children}
		</>
	);
}

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<Root>
				<Today />
			</Root>
		),
	},
	{
		path: '/month',
		element: (
			<Root>
				<Month />
			</Root>
		),
	},
	{
		path: '/scheduler',
		element: (
			<Root>
				<Scheduler />
			</Root>
		),
	},
	{
		path: '/sales',
		element: (
			<Root>
				<Sales />
			</Root>
		),
	},
	{
		path: '/metrics',
		element: (
			<Root>
				<Metrics />
			</Root>
		),
	},
	{
		path: '/leaderboard',
		element: (
			<Root>
				<Leaderboard />
			</Root>
		),
	},
	{
		path: '/day-review',
		element: (
			<Root>
				<DayReview />
			</Root>
		),
	},
	{
		path: '/content',
		element: (
			<Root>
				<Content />
			</Root>
		),
	},
]);

export default function () {
	return <RouterProvider router={router} />;
}
